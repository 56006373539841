<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>运动票列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="产品名称">
                                <el-input v-model="search.title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="标签名称">
                                <el-input v-model="search.tag"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="title"
                        label="产品名称"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="store_uuid"
                        label="适用门店"
                        width="200">
                    <template slot-scope="scope">
                        <span>{{ scope.row.store_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="tag"
                        label="产品标签"
                        width="200">
                    <template slot-scope="scope">
                        <el-tag
                                :key="item.index"
                                v-for="item in scope.row.tag"
                                class="form-tag_colour-tag"
                                effect="dark"
                                size="mini"
                                :color="item.tag_colour">
                            {{ item.tag_name }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="originally_price"
                        label="产品原价"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="promotion_price"
                        label="优惠价格"
                        width="200">
                    <template slot-scope="scope">
                        <span>￥{{ scope.row.promotion_price }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="duration"
                        label="时长"
                        width="200">
                    <template slot-scope="scope">
                        <span>{{ scope.row.duration }}分钟</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="searchtime"
                        label="有效期"
                        width="200">
                    <template slot-scope="scope" v-if="scope.row.expiration_time!='1970-01-01 08:33'">
                        <span>{{ scope.row.expiration_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="usage_time"
                        label="使用时间"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="advance_booking"
                        label="预约信息"
                        width="200">
                    <template slot-scope="scope">
                        <span>提前{{ scope.row.advance_booking }}天预约</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="fraction_limit"
                        label="单次最多可购买"
                        width="200">
                    <template slot-scope="scope">
                        <span>{{ scope.row.fraction_limit }}份</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="stock"
                        label="库存数量"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="is_refund"
                        label="允许退款"
                        width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.is_refund===1">是</el-tag>
                        <el-tag size="mini" v-if="scope.row.is_refund===0" type="warning">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="refund_remarks"
                        label="退款备注"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="publish_state"
                        label="上下架状态"
                        width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.publish_state===0" type="danger">下架</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state===1" type="success">上架</el-tag>
                        <el-tag size="mini" v-if="scope.row.publish_state===2" type="warning">审核中</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        width="300">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('product.sportsticket.getinfo')"
                                @click="tosee(scope.row.good_uuid)"
                                size="mini">查看
                        </el-button>
                        <el-button
                                v-if="is_auth('product.sportsticket.examine') && scope.row.state===1 "
                                @click="operation_tip(scope.row.good_uuid,scope.row.title,'examine',1)"
                                type="success" size="mini">审核通过
                        </el-button>
                        <el-button
                                v-if="is_auth('product.sportsticket.examine') && scope.row.state===1 "
                                @click="operation_tip(scope.row.good_uuid,scope.row.title,'examine',3)"
                                type="warning" size="mini">审核不通过
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '运动票列表',
            loading: true,      // 加载状态
            issearch: true,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()

    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                title: '',
                publish_state: 2,
                tag: '',
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "product.sportsticket.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(good_uuid = '', title = '', operation = '', publish_state = '') {

            let tip = ''
            let options = {}
            // 上架
            if (publish_state === 1) {
                tip = '审批通过【' + title + '】？'
            }
            // 下架
            if (publish_state === 3) {
                tip = '审批不通过【' + title + '】？'
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(good_uuid, operation, publish_state)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(good_uuid = '', operation = '', publish_state) {
            let postdata = {
                api_name: "product.sportsticket." + operation,
                token: this.Tool.get_l_cache('token'),
                good_uuid: good_uuid,
                publish_state
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(good_uuid = '') {
            this.$router.push({path: '/product/sportsticket/details', query: {good_uuid: good_uuid}})
        },


    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
